.masthead {
	position: relative;
	width: 100%;
	height: auto;
	min-height: 35rem;
	padding: 15rem 0;
	background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.3) 0%,
			rgba(0, 0, 0, 0.3) 90%,
			rgba(0, 0, 0, 0) 100%
		),
		url("../assets/bg-masthead.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
}

.masthead h1 {
	font-size: 2.5rem;
	line-height: 2.5rem;
	letter-spacing: 0.8rem;
	color: #ffffff;
}

.masthead h2 {
	max-width: 20rem;
	font-size: 1rem;
}

@media (min-width: 768px) {
	.masthead h1 {
		font-size: 4rem;
		line-height: 4rem;
	}
}

@media (min-width: 992px) {
	.masthead {
		height: 100vh;
		padding: 0;
	}

	.masthead h1 {
		font-size: 6.5rem;
		line-height: 6.5rem;
		letter-spacing: 0.8rem;
	}

	.masthead h2 {
		max-width: 30rem;
		font-size: 1.25rem;
	}
}
